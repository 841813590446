import $ from 'jquery';

document.addEventListener("turbolinks:load", function() {

  // Create a Checkout Session with the selected plan ID
  var createCheckoutSession = function(planId) {
    return fetch("/subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        authenticity_token: $('meta[name="csrf-token"]').attr('content'),
        planId: planId
      })
    }).then(function(result) {
      return result.json();
    });
  };

  // Handle any errors returned from Checkout
  var handleResult = function(result) {
    if (result.error) {
      alert(result.error.message);
      Turbolinks.visit('/');
    }
  };


  var stipePublicKey = $('body').data('stripe-public-key');
  var stripe = Stripe(stipePublicKey);

  // Setup event handler to create a Checkout Session when button is clicked
  $('.purchase_plan').on('click', function(){
    $(this).html('<span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span> Loading...').attr('disabled', true);

    var planId = $(this).data('plan-id');

    createCheckoutSession(planId).then(function(data) {

      if(data.user_signed_in) {

        // Call Stripe.js method to redirect to the new Checkout page
        stripe.redirectToCheckout({
          sessionId: data.sessionId
        }).then(handleResult);

      } else {
        Turbolinks.visit('/users/sign_up')
      }

    });
  });

  // check if need to complete checkout after sign in/sign up
  if(user_signed_in && $('.purchase_plan')) {
    $(".purchase_plan[data-complete-checkout='true']").click();
  }

  // Update payment method
  $('#update-payment-method').click(function(e){
    e.preventDefault();

    var session_id = $(this).data('session-id');

    stripe.redirectToCheckout({
      sessionId: session_id
    }).then(handleResult);

  });

});
