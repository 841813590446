import $ from 'jquery';

document.addEventListener("turbolinks:load", function() {

  // Create a Checkout Session with the selected course ID
  var createCheckoutSession = function(courseId) {
    return fetch("/courses/" + courseId + "/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        authenticity_token: $('meta[name="csrf-token"]').attr('content'),
        company_invite_code: $("#company_invite_code_" + courseId).val()
      })
    }).then(function(result) {
      return result.json();
    });
  };

  // Handle any errors returned from Checkout
  var handleResult = function(result) {
    if (result.error) {
      alert(result.error.message);
      Turbolinks.visit('/');
    }
  };


  var stipePublicKey = $('body').data('stripe-public-key');
  var stripe = Stripe(stipePublicKey);

  // Setup event handler to create a Checkout Session when button is clicked
  $('.buy_course').on('click', function(){
    $(this).html('<span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span> Loading...').attr('disabled', true);

    var courseId = $(this).data('course-id');

    createCheckoutSession(courseId).then(function(data) {
      if(data.sessionId) {

        // Call Stripe.js method to redirect to the new Checkout page
        stripe.redirectToCheckout({
          sessionId: data.sessionId
        }).then(handleResult);

      } else {
        Turbolinks.visit(data.redirect_to || '/users/sign_up')
      }
    });
  });

});
