import $ from 'jquery';
import NProgress from 'nprogress/nprogress.js';

$(document).on('turbolinks:click', function() {
  NProgress.start();
});

$(document).on('turbolinks:render', function() {
  NProgress.done();
  NProgress.remove();
});